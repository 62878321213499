import memoizee from "memoizee";
import React, {useRef} from "react";
import {Transition, TransitionStatus} from "react-transition-group";
import {EnterHandler, ExitHandler} from "react-transition-group/Transition";

import {cloneChildren} from "../../utils/cloneChildren";

const getTransitionStyles = (state: TransitionStatus): React.CSSProperties =>
  ({
    entering: {opacity: 0},
    entered: {opacity: 1},
    exiting: {opacity: 1},
    exited: {opacity: 0},
    unmounted: {opacity: 0},
  }[state]);

const cloneChildrenWithStyles = memoizee(
  (
    state: TransitionStatus,
    children:
      | React.ReactElement<{style: React.CSSProperties}>
      | React.ReactElement<{style: React.CSSProperties}>[],
    duration: number,
  ) =>
    cloneChildren(children, ({props}) => ({
      style: {
        transition: `opacity ${duration}ms ease-in-out`,
        ...getTransitionStyles(state),
        ...(props.style || {}),
      },
    })),
);

const Fade = ({
  duration = 150,
  children,
  timeout = 0,
  ...transitionProps
}: {
  in?: boolean;
  duration?: number;
  timeout?: number;
  appear?: boolean;
  unmountOnExit?: boolean;
  mountOnEnter?: boolean;
  children:
    | React.ReactElement<{style: React.CSSProperties}>
    | React.ReactElement<{style: React.CSSProperties}>[];
  onEnter?: EnterHandler<undefined>;
  onEntering?: EnterHandler<undefined>;
  onEntered?: EnterHandler<undefined>;
  onExit?: ExitHandler<undefined>;
  onExiting?: ExitHandler<undefined>;
  onExited?: ExitHandler<undefined>;
}): React.ReactElement | null => {
  const transitionRef = useRef<null>(null);

  return (
    <Transition nodeRef={transitionRef} timeout={timeout} {...transitionProps}>
      {state => cloneChildrenWithStyles(state, children, duration)}
    </Transition>
  );
};

export default React.memo(Fade);
