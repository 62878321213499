import React, {PropsWithChildren} from "react";

type Props = {
  hrefs: string[];
  style?: React.CSSProperties;
};

const SkipSection: React.FC<PropsWithChildren<Props>> = ({children, hrefs, style}) => {
  const handleSpaceAndEnter = () => {
    for (let i = 0; i < hrefs.length; i += 1) {
      const href = hrefs[i];
      const el = document.getElementById(href); // scroll to first element in hrefs found in DOM
      if (el) {
        window.location.href = `#${href}`;
        el.focus();
        break;
      }
    }
  };

  return (
    <button
      className="pos-a-f bg-white gray800 ph4 pv3 br5 showOnFocus focus-bsDarkBlue3 brdn"
      style={style}
      onClick={handleSpaceAndEnter}
    >
      {children}
    </button>
  );
};

export default SkipSection;
