import uniq from "lodash/uniq";
import memoizee from "memoizee";

import {useTypedSelector} from "../store";
import {selectSelectedRegion} from "../store/slices/userLocation";

const getRegionFromLoc = (l: PartialLocation) => l.region.slug;

type PartialLocation = {region: {slug: string}; specialtyIds: string[]};

export const buildRegionSpecialtyMap = memoizee((locations: PartialLocation[]) =>
  locations.reduce<{[key: string]: string[]}>((acc, next) => {
    const key = getRegionFromLoc(next);
    const prevList = acc[key] || [];
    return {
      ...acc,
      [key]: uniq([...prevList, ...next.specialtyIds]),
    };
  }, {}),
);

export const useSpecialtiesInRegion = (): string[] => {
  const locations = useTypedSelector(state => state.config.locations);
  const selectedRegion = useTypedSelector(selectSelectedRegion);
  const map = locations && buildRegionSpecialtyMap(locations);
  return map ? map[selectedRegion] : [];
};
