import React from "react";

export const cloneChildren = <T>(
  children: React.ReactElement<T> | React.ReactElement<T>[],
  getNewProps: (child: React.ReactElement<T>, i: number) => Partial<T>,
): React.ReactElement<T>[] =>
  React.Children.map(children, (child, i) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, getNewProps(child, i));
    }
    return child;
  });
