import {colors} from "@c10h/colors";
import SkipSection from "@components/SkipSection";
import {useTranslation} from "ni18n";
import React, {useState} from "react";
import {useSwipeable} from "react-swipeable";
import {useTypedSelector} from "src/store";

import {useClientSideId} from "../../hooks/useClientSideId";
import useMediaQuery from "../../useMediaQuery";
import {isCI} from "../_common/_constants";
import {useInterval} from "../_common/Carbon";

export type Testimonial = {
  body: string;
  head?: string;
  who?: {name?: string; who?: string | null};
  bg?: string;
  color?: string;
  headColor?: string;
  source?: string;
  headImg?: JSX.Element;
  hideHeadTitle?: boolean;
};

export type TestiPropsType = {
  defaultTestimons?: Testimonial[];
  defaultHead?: string | JSX.Element;
  autoRotate?: boolean;
  firstBgColor?: string;
  firstTextColor?: string;
  smallFont?: boolean;
  ariaLabel?: string;
};

const TestimonialSlider = ({
  defaultTestimons,
  defaultHead,
  autoRotate,
  firstBgColor,
  firstTextColor,
  smallFont = false,
  ariaLabel,
}: TestiPropsType) => {
  const [k, setK] = useState(0);
  const i18n = useTranslation();
  const {isMobile} = useTypedSelector(({config}) => config);

  useInterval(
    () => {
      if ((autoRotate || isMobile) && !isCI) {
        setK((k + 1) % testimons.length);
      }
    },
    8000,
    false,
  );

  const head = defaultHead || i18n.t("What our patients say");

  const testimons: Testimonial[] = defaultTestimons || [
    {
      body: i18n.t(
        "I highly recommend them to anyone needing medical attention. Reservations are easy to set up via their app and recommended to avoid wait time. Yelp only allows for 5 stars. I would give them a 10.",
      ),
      who: {
        name: "Gary",
        who: i18n.t("Carbon Health San Francisco Patient"),
      },
      bg: firstBgColor || colors.darkerMint,
      color: firstTextColor || "#fff",
    },
    {
      body: i18n.t(
        "Oh my God, finally an urgent care in our neighborhood that doesn't actually feel like an urgent care!  The space is super clean, modern, open and airy. The staff was excellent and were super friendly.",
      ),
      who: {
        name: "Gina",
        who: i18n.t("Carbon Health Los Angeles Patient"),
      },
      bg: colors.darkPurple,
      color: "#fff",
    },
    {
      body: i18n.t(
        "If I could give this place 10 stars, I would. I received exceptional service from the moment I walked in, to the time I left. This place has been the best urgent care location I've ever been to.",
      ),
      who: {
        name: "Steph",
        who: i18n.t("Carbon Health San Jose Patient"),
      },
      bg: colors.darkerYellow,
    },
    {
      body: i18n.t(
        "Literally amazing. They have the nicest doctors and staff, and you can get an appointment so easily and so fast! ",
      ),
      who: {
        name: "Liana",
        who: i18n.t("Carbon Health San Francisco Patient"),
      },
      bg: colors.darkBlue,
    },
    {
      body: i18n.t(
        "Appreciated being seen immediately, the friendly and helpful staff, and my comprehensive care including x-rays, prescriptions called into my pharmacy and scheduling a follow-up appointment.",
      ),
      who: {
        name: "Laurie",
        who: i18n.t("Carbon Health Oakland Patient"),
      },
      bg: colors.darkerMint,
    },
    {
      body: i18n.t(
        "What I love is through their app (make sure you download the app - worth it) they check up on you to make sure you are ok. You are able to share any new developments.",
      ),
      who: {
        name: "Joeletta",
        who: i18n.t("Carbon Health Mountain View Patient"),
      },
      bg: colors.darkPurple,
    },
    {
      body: i18n.t(
        "Great urgent care! Easy to make an appointment, got seen right away, the place was clean and tidy, and the staff was extremely friendly and helpful.",
      ),
      who: {
        name: "Tessa",
        who: i18n.t("Carbon Health Berkeley Patient"),
      },
      bg: colors.darkerYellow,
    },
  ];

  const activeItem = testimons[k];

  const swipeHandlers = useSwipeable({
    onSwipedRight: () => isMobile && changeSlide(-1),
    onSwipedLeft: () => isMobile && changeSlide(1),
  });

  const changeSlide = (val: number) => {
    const nextVal =
      k + val > testimons.length - 1 ? 0 : k + val < 0 ? testimons.length - 1 : k + val;

    setK(nextVal);
  };

  const isXs = useMediaQuery("xs");

  const headingId = useClientSideId();
  const carouselId = useClientSideId();

  return (
    <section
      className="lg:py-36 md:py-16 pt-16 pb-10 relative text-white contrast-tbt max-w-screen transition-colors duration-500"
      style={{
        backgroundColor: testimons[k].bg,
      }}
      aria-labelledby={headingId}
      aria-roledescription="carousel"
      {...(ariaLabel && {"aria-label": ariaLabel})}
      {...swipeHandlers}
    >
      <div className="relative m-auto px-4 md:px-24">
        <SkipSection
          hrefs={["explore-locations", "footer"]} // id="explore-locations" may not exist in dom, fallback to id="footer"
          style={{left: "2rem", top: "-4rem"}}
        >
          {i18n.t("Skip Carousel")}
        </SkipSection>
        <div>
          {!activeItem.hideHeadTitle && (
            <h2 className="font-ir fs13 op1 ls2 mb8 mt0 ttu" id={headingId}>
              {activeItem.head || head}
            </h2>
          )}
          {activeItem.headImg && <div className="mb8 minh20">{activeItem.headImg}</div>}
          <div className="relative overflow-hidden">
            <div
              className="transition-transform duration-500 grid grid-flow-col"
              style={{gridAutoColumns: "100%", transform: `translateX(${-100 * k}%)`}}
              id={carouselId}
              aria-live={autoRotate || isMobile ? "off" : "polite"}
            >
              {testimons.map((t, i) => (
                <blockquote
                  key={i}
                  className={`t-op200 pos-r df fdc op0 brdn pl0 m0 ${i === k ? "op1" : ""}`}
                  role="group"
                  aria-label={i18n.t(" {{number}} of {{total}}", {
                    number: i + 1,
                    total: testimons.length,
                  })}
                  aria-hidden={i !== k}
                >
                  <p
                    className={`font-cm mb8 ${
                      smallFont ? "fs28 fs18-md" : "fs40 fs24-md"
                    } lh12 lh14-md maxw64 pb24 pb16-md fx`}
                  >
                    {t.body}
                  </p>
                  {t.who && <div className="font-cm fs20 fs18-md mb2">{t.who.name}</div>}
                  {t.who && <div className="font-cm fs20 fs18-md lh14 maxw12-md">{t.who.who}</div>}
                  {t.source && (
                    <a
                      href={t.source}
                      rel="noopener noreferrer"
                      target="_blank"
                      className="white fs14 font-ir us pos-a"
                      style={{bottom: 0}}
                    >
                      {i18n.t("Source")}
                    </a>
                  )}
                </blockquote>
              ))}
            </div>
          </div>
        </div>

        {testimons.length > 1 && (
          <div
            className="df aic pos-a pos-r-xs mt4-xs"
            style={isXs ? {} : {right: "2rem", bottom: isMobile ? ".25rem" : "-1rem"}}
          >
            <div className="font-mb fs12 mr6 ttu">
              {i18n.t(" {{number}} of {{total}}", {
                number: k + 1,
                total: testimons.length,
              })}
            </div>
            {!isMobile && (
              <>
                <button
                  style={{backgroundColor: colors.darkerGreen + 33}} // HEX+
                  onClick={() => changeSlide(-1)}
                  className="cp br50 p2 fs36 cIcon-arrow-left mr4 brdn white contrast-tb"
                  aria-controls={carouselId}
                  aria-label={i18n.t("Previous")}
                />
                <button
                  style={{backgroundColor: colors.darkerGreen + 33}} // HEX+
                  onClick={() => changeSlide(1)}
                  className="cp br50 p2 fs36 cIcon-arrow-right2 brdn white contrast-tb"
                  aria-controls={carouselId}
                  aria-label={i18n.t("Next")}
                />
              </>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default TestimonialSlider;
